<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import { ensureNumber } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { usePoints } from '/~/composables/points'

export default {
  name: 'points-panel',
  components: {
    BaseIcon,
  },
  props: {
    total: {
      type: [String, Number],
      default: 0,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isPointsEnabled } = usePoints()

    const label = computed(() =>
      (props.completed ? 'POINTS EARNED' : 'POINTS TO BE EARNED').toUpperCase()
    )
    const totalNumber = computed(() => Math.floor(ensureNumber(props.total)))
    const isShow = computed(() =>
      Boolean(isPointsEnabled.value && totalNumber.value)
    )

    return {
      isShow,
      label,
      totalNumber,
      formatPoints,
      ui,
    }
  },
}
</script>

<template>
  <div
    v-if="isShow"
    class="flex h-[88px] flex-1 items-center justify-center space-x-[30px] bg-primary-lightest px-5 py-[15px] sm:space-x-5"
  >
    <div class="flex items-center justify-end">
      <base-icon
        class="text-primary"
        svg="points/token"
        :size="ui.desktop ? 34 : 45"
      />
    </div>
    <div
      class="flex flex-col items-start justify-center space-y-[5px] font-semibold"
    >
      <span class="text-sm font-normal">
        {{ label }}
      </span>
      <div class="relative text-4xl leading-none sm:text-2xl">
        <span
          v-if="loading"
          class="w-54 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
        >
          loading
        </span>
        <template v-else>
          <span>+{{ formatPoints(totalNumber) }}</span>
          <span class="text-sm">PTS</span>
        </template>
      </div>
    </div>
  </div>
</template>
